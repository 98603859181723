import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

// components
import PageTitle from "../../../components/PageTitle";

import { FormInput } from "../../../components";

import ApiHandlers from "../../../helpers/api/handlers";
import { useToast } from "../../../hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import MyModal from "../../../components/shared/Modal";
import ModalAlert from "../../../components/shared/ModalAlert";
import { useQueries } from "@tanstack/react-query";
import { store } from "../../../redux/store";
import { setLoading } from "../../../redux/actions";
import Table from "../../../components/Table";
import classNames from "classnames";

interface OptionTypes {
  data: string;
  pickupAddress: any;
  id: number | string;
  value: string;
  label: string;
  orderId: string;
  user: any;
  noOfBottles: number;
  zipcode: string;
}

const ModalComponent = ({
  submissionId,
  CloseOpenedNextModal,
  resetPage,
}: {
  submissionId: string;
  CloseOpenedNextModal: () => void;
  resetPage: () => void;
}) => {
  const [otp, setOtp] = useState<string | null>(null);
  const [modal, showModal] = useState<boolean>(false);

  const { toast } = useToast();
  const dispatch = useDispatch();
  const SubmitSubmissionData = async () => {
    dispatch(setLoading(true));
    if (otp === null) {
      dispatch(setLoading(false));

      return toast({ title: "OTP is required Before Submit" });
    }
    const { data } = await ApiHandlers.markOrderAsCompleted({
      submissionId,
      otp,
    });
    if (!data.success) {
      dispatch(setLoading(false));

      return toast({
        title: "Something Went Wrong",
        description: data.message,
      });
    }
    dispatch(setLoading(false));
    CloseOpenedNextModal();
    toggleMyAlertModal();
    resetPage();
  };
  const toggleMyAlertModal = async () => showModal(!modal);
  return (
    <React.Fragment>
      <Row>
        <h3 className="text-dark">Please Enter OTP </h3>
        <Col>
          <FormInput
            name="otp"
            placeholder="Enter six digit OTP"
            containerClass={"mb-3"}
            maxLength={6}
            minLength={6}
            required
            onChange={(e) => setOtp(e.target.value)}
          />
        </Col>
        <Button
          variant="primary"
          onClick={SubmitSubmissionData}
          className="waves-effect waves-light m-1"
        >
          Submit
        </Button>
      </Row>
      <ModalAlert
        modal={modal}
        toggleModal={toggleMyAlertModal}
        bgColor="success"
      >
        <div className="text-center">
          <i className="dripicons-checkmark h1 text-white"></i>
          <p className="mt-3 text-white">
            This order has been marked as PAID and completed
          </p>
          <button
            type="button"
            onClick={toggleMyAlertModal}
            className="btn btn-light my-2"
          >
            OK
          </button>
        </div>
      </ModalAlert>
    </React.Fragment>
  );
};
const ProjectForm = () => {
  const { toast } = useToast();
  const dispatch = useDispatch();
  const { aid } = useSelector((state: any) => state.Auth.user);
  const [modal, showModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<string | number>(0);
  const [singleSelections, setSingleSelections] = useState<OptionTypes[]>([]);
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<Array<AssignedRecords> | null>(
    null
  );
  const [allOrders, setAllOrders] = useState<OptionTypes[] | null>(null);
  const [bottlesQty, setBottlesQty] = useState<string | number>(0);
  const [nextModal, setNextModal] = useState<boolean>(false);

  const methods = useForm();
  const {
    register,
    control,
    formState: { errors },
  } = methods;
  let currentValue: any;
  const onChangeSingleSelection = (selected: OptionTypes[]) =>
    setSingleSelections(selected);
  const fetchAllSubmissions = React.useCallback(async () => {
    const response = await ApiHandlers.getDriverSubmissionsAssignedToHim(aid);
    if (!response.data.success) {
      return toast({
        title: "Something went wrong",
        description: response.data.message,
      });
    }
    setUserDetails(response.data.result);
    const validatedData = response.data.result.map(
      (submission: { id: string; orderId: string }) => {
        return {
          value: submission.id,
          label: submission.orderId,
          ...submission,
        };
      }
    );
    setAllOrders(validatedData);
    return validatedData;
  }, [aid]);
  const query = useQueries({
    queries: [
      {
        queryKey: ["getAllSubmissions", 1],
        queryFn: fetchAllSubmissions,
        refetchInterval: 10000,
      },
      {
        queryKey: ["getDefaultAmount", 2],
        queryFn: ApiHandlers.getDeafultAmount,
        refetchInterval: 10000,
      },
    ],
  });
  const getDefaultAmount = query[1].data?.data?.result[0];
  const defaultAmountValue = Number(getDefaultAmount?.deafultAmount) || 0;

  const [lastBottlesQty, setLastBottlesQty] = useState<string | number>(0);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize amount with default value only once when data is available
  useEffect(() => {
    if (!isInitialized && defaultAmountValue > 0) {
      setAmount(defaultAmountValue);
      setIsInitialized(true);
      console.log(
        "File: DriverForm.tsx 🔄 Line: 45, Function: useEffect; defaultAmount:",
        defaultAmountValue
      );
    }
  }, [defaultAmountValue, isInitialized]);

  // Handle bottle quantity changes
  useEffect(() => {
    if (isInitialized) {
      handleBottleQuantityChange(bottlesQty.toString());
    }
  }, [bottlesQty, isInitialized]);

  const handleBottleQuantityChange = (bottles: string) => {
    if (!bottles || bottles === lastBottlesQty) {
      return;
    }

    const bottleCount = Number(bottles);

    if (bottleCount < 0) {
      setBottlesQty(0);
      toast({ title: "Bottle Quantity cannot be lower than 0" });
      return;
    }

    if (bottleCount === 0) {
      setBottlesQty(0);
      // toast({ title: "Bottle Quantity cannot be 0" });
      // return;
    }

    try {
      // Calculate new amount using default amount per bottle
      const newAmount = defaultAmountValue * bottleCount;

      if (isNaN(newAmount)) {
        throw new Error("Invalid calculation result");
      }

      setLastBottlesQty(bottles);
      setAmount(newAmount);
      setBottlesQty(bottles);

      console.log(
        "File: DriverForm.tsx 📊 Line: 80, Function: handleBottleQuantityChange;",
        {
          bottles,
          defaultAmount: defaultAmountValue,
          newAmount,
        }
      );
    } catch (error) {
      console.error(
        "File: DriverForm.tsx ❌ Line: 87, Function: handleBottleQuantityChange;",
        error
      );
      toast({
        title: "Error calculating amount",
        description: "Please try again or contact support",
      });
    }
  };
  const toggleModal = () => {
    if (Number(bottlesQty) === 0 || bottlesQty === "") {
      return toast({
        title: "Please Enter Bottle Quantity",
        description: "Bottle Quantity cannot be 0 or empty",
      });
    }

    showModal(!modal);
  };
  const toggleNextModal = async () => {
    toast({ title: "Sending OTP", description: "Please wait" });
    dispatch(setLoading(true));
    const { data } = await ApiHandlers.readyForPayouts({
      submissionId: singleSelections[0].value,
      amount: amount,
      bottleQty: bottlesQty,
      isSkiped:false,
    });
    if (!data.success) {
      dispatch(setLoading(false));
      return toast({
        title: "Something went wrong",
        description: data.message,
      });
    }
    showModal(false);
    toast({ title: "OTP Sent Successfully" });
    dispatch(setLoading(false));
    setNextModal(!nextModal);
  };
  const toggleSkipModal = async () => {
    toast({ title: "Sending OTP", description: "Please wait" });
    dispatch(setLoading(true));
    const { data } = await ApiHandlers.readyForPayouts({
      submissionId: singleSelections[0].value,
      amount: amount,
      bottleQty: bottlesQty,
      isSkiped:true,
    });
    if (!data.success) {
      dispatch(setLoading(false));
      return toast({
        title: "Something went wrong",
        description: data.message,
      });
    }
    showModal(false);
    data.OTP && toast({ title: `OTP ${data.OTP}` });
    dispatch(setLoading(false));
    setNextModal(!nextModal);
  };
  const CloseOpenedNextModal = async () => setNextModal(false);
  const refreshData = () => {
    query[0].refetch();
    query[1].refetch();
  };
  const resetPage = () => {
    setSingleSelections([]);
    setAmount(0);
    setBottlesQty(0);
    setShowUserDetails(false);
    refreshData();
  };
  const maskedDetails = (value: string) => {
    let maskedData: string = "";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Check if the input is a valid mobile number
    if (
      typeof value !== "string" ||
      value.length === 10 ||
      /^\d+$/.test(value)
    ) {
      maskedData = "*****" + value.slice(5);
      return maskedData;
    }
    if (typeof value === "string" || emailRegex.test(value)) {
      const [localPart, domainPart] = value.split("@");
      const maskedLocalPart =
        "*".repeat(Math.ceil(localPart.length / 2)) +
        localPart.slice(Math.ceil(localPart.length / 2));
      maskedData = maskedLocalPart + "@" + domainPart;
      return maskedData;
    }

    return maskedData;
  };
  function handleChange() {
    let previousValue = currentValue;

    currentValue = store.getState().Notifications.latestNotifications.length;

    if (previousValue !== undefined && previousValue !== currentValue) {
      refreshData();
    }
    unsubscribe();
  }

  const DeliveryStatusColumn = ({ row }: { row: any }) => {
    return (
      <>
        <h5>
          <span
            className={classNames("badge", {
              "bg-success text-light fs-6":
                row.original.deliveryStatus[
                  row.original.deliveryStatus.length - 1
                ] === "Completed",
              "bg-primary text-light fs-6":
                row.original.deliveryStatus[
                  row.original.deliveryStatus.length - 1
                ] === "Order Picked",
              "bg-warning text-light fs-6":
                row.original.deliveryStatus[
                  row.original.deliveryStatus.length - 1
                ] === "Processing",
              "bg-danger text-light fs-6":
                row.original.deliveryStatus[
                  row.original.deliveryStatus.length - 1
                ] === "Cancelled",
            })}
          >
            {
              row.original.deliveryStatus[
                row.original.deliveryStatus.length - 1
              ]
            }
          </span>
        </h5>
      </>
    );
  };

  const unsubscribe = store.subscribe(handleChange);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Orders Details",
            path: "/apps/orders",
            active: true,
          },
        ]}
        title={"Orders Details"}
      />
      <Row>
        <div
          className="alert alert-info alert-dismissible fade show"
          role="alert"
        >
          You have been assigned <strong>{allOrders?.length}</strong> New Pickup
          Orders
        </div>
      </Row>
      {query[1].data?.data?.result.length === 0 && (
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>
            There is Bottle Amount setted. Please Contact to Administrator
          </strong>
        </div>
      )}
      {singleSelections.length > 0 &&
        query[1].isFetched &&
        query[1].isSuccess && (
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Row>
                    <Col xl={6}>
                      <FormInput
                        name="amount"
                        label="Default Amount"
                        containerClass={"mb-3"}
                        register={register}
                        key="name"
                        errors={errors}
                        control={control}
                        onChange={(e: any) =>
                          setAmount(parseFloat(e.target.value))
                        }
                        defaultValue={getDefaultAmount.deafultAmount}
                      />
                    </Col>
                    <Col xl={6}>
                      <FormInput
                        name="numberOfBottles"
                        label="Number Of Bottles"
                        placeholder="Enter Number Of Bottles"
                        type="number"
                        onChange={(e: any) =>{
                          handleBottleQuantityChange(e.target.value)
                        }
                        }
                        value={bottlesQty}
                        containerClass={"mb-3"}
                        register={register}
                        key="numberOfBottles"
                        defaultValue={bottlesQty}
                        errors={errors}
                        control={control}
                      />
                    </Col>
                    {showUserDetails && userDetails && userDetails[0].user && (
                      <Col lg={12} className="text-center">
                        <h4 className="fw-bold text-dark">
                          User Full Name :{" "}
                          {
                            (
                              userDetails[0]?.user &&
                              userDetails?.filter(
                                (item: any) =>
                                  item.orderId === singleSelections[0]?.label
                              )[0]
                            )?.user?.fullname
                          }
                        </h4>
                        <h4 className="fw-bold text-dark">
                          User Email :{" "}
                          {maskedDetails(
                            (
                              userDetails[0]?.user &&
                              userDetails?.filter(
                                (item: any) =>
                                  item.orderId === singleSelections[0]?.label
                              )[0]
                            )?.user?.email
                          )}
                        </h4>
                        <h4 className="fw-bold text-dark">
                          User Phone :{" "}
                          {userDetails &&
                            userDetails[0].user &&
                            (
                              userDetails[0]?.user &&
                              userDetails?.filter(
                                (item: any) =>
                                  item.orderId === singleSelections[0]?.label
                              )[0]
                            )?.user?.phone}
                        </h4>
                      </Col>
                    )}

                    <Col className="text-center" lg={12}>
                      <h4 className="fw-bold text-dark">
                        Pickup Address : {singleSelections[0]?.pickupAddress}
                      </h4>

                      <h4 className="fw-bold text-dark">
                        Prefered Time:{" "}
                        {(JSON.parse(singleSelections[0]?.data) || {})[
                          "what is your preferred time ?"
                        ] ||
                          (JSON.parse(singleSelections[0]?.data) || {})[
                            "which character is missing a b d ?"
                          ] ||
                          "Not Provided"}
                      </h4>
                      <h4 className="fw-bold text-success">
                        Total Payout: {getDefaultAmount.symbol}{" "}
                        {bottlesQty === 0 ? 0 : Number(amount).toFixed(2)}
                      </h4>
                      <small>
                        This amount will in Dollars ({getDefaultAmount.currency}
                        )
                      </small>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col className="text-center">
                      <Button
                        onClick={() => setSingleSelections([])}
                        variant="light"
                        className="waves-effect waves-light m-1"
                      >
                        <i className="fe-x me-1"></i> Cancel
                      </Button>
                      <Button
                        onClick={toggleModal}
                        variant="success"
                        className="waves-effect waves-light m-1"
                      >
                        <i className="fe-check-circle me-1"></i> Proceed To Pay
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => setShowUserDetails(!showUserDetails)}
                        className="waves-effect waves-light m-1"
                      >
                        <i className="fe-user-check me-1"></i>Verify User
                        Details
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Modal
              dialogClassName="modal-dialog-centered"
              show={modal}
              backdrop="static"
              onHide={toggleModal}
            >
              <Modal.Header closeButton>
                <h4 className="modal-title">
                  {" "}
                  Procceed To Complete This Order
                </h4>
              </Modal.Header>
              <Modal.Body>
                <h4>OTP will be sent to the customer</h4>
                <p className="text-dark">
                  Please Enter the OTP provided by the user.
                </p>
              </Modal.Body>
              <Modal.Footer style={{display:"flex",justifyContent:"space-between"}}>
              <Button variant="secondary" onClick={toggleSkipModal}>
                  Skip
                </Button>
                <Button variant="primary" onClick={toggleNextModal}>
                  Next
                </Button>
              </Modal.Footer>
            </Modal>
            <MyModal
              backdrop={{ backdrop: "static" }}
              modal={nextModal}
              toggle={() => setNextModal(!nextModal)}
              align="center"
              header={
                <h5 className="text-primary fw-bold">Complete This Order</h5>
              }
            >
              <ModalComponent
                resetPage={resetPage}
                submissionId={singleSelections[0]?.value}
                CloseOpenedNextModal={CloseOpenedNextModal}
              />
            </MyModal>
          </Row>
        )}

      <div>
        <Table
          columns={[
            {
              Header: "Order ID",
              accessor: "orderId",
            },
            {
              Header: "Order Date & Time",
              accessor: "created_at",
              Cell: (row: any) => {
                return (
                  <h5 className="fw-bold text-dark">
                    {new Date(row.row.original.created_at).toLocaleString(
                      "en-US",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}
                  </h5>
                );
              },
            },
            {
              Header: "User",
              accessor: "user.fullname",
            },
            {
              Header: "Bottles",
              accessor: "bottles",
            },
            {
              Header: "Pickup Address",
              accessor: "pickupAddress",
            },
            {
              Header: "Delivery Status",
              accessor: "deliveryStatus[deliveryStatus.length - 1].label",
              Cell: DeliveryStatusColumn,
            },
            {
              Header: "Prefered Time",
              accessor: "data['what is your preferred time ?']",
              Cell: (row: any) => {
                const data = JSON.parse(row.row.original.data);
                const preferedTime = data["what is your preferred time ?"];
                const missingCharacter =
                  data["which character is missing a b d ?"];
                return (
                  <h5 className="fw-bold text-dark">
                    {preferedTime || missingCharacter || "Not Provided"}
                  </h5>
                );
              },
            },
            {
              Header: "Action",
              Cell: (row: any) => {
                return (
                  <Button
                    onClick={() => {
                      setSingleSelections([row.row.original]);
                      setShowUserDetails(true);
                    }}
                  >
                    View Order
                  </Button>
                );
              },
              accessor: "action",
            },
          ]}
          data={allOrders ?? []}
          isSearchable={true}
          pagination={true}
        />
      </div>
    </>
  );
};

export default ProjectForm;
export interface AssignedRecords {
  id: string;
  orderId: string;
  user: User;
}

export interface User {
  id: string;
  fullname: string;
  phone: string;
  address: string;
  email: string;
}
